function getStarterConfig() {
  switch (process.env.GATSBY_STARTER_CONFIG) {
    case "development":
      return {
        pathPrefix: "/",
        origin: "http://localhost:8000",
      };
    case "netlify":
      return {
        pathPrefix: "/",
        origin: process.env.GATSBY_DEPLOY_PRIME_URL,
      };
    case "production":
    default:
      return {
        pathPrefix: "/ddj/deutschland-wo-brennts/",
        origin: "https://data.wdr.de",
      };
  }
}

module.exports = getStarterConfig();
