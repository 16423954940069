import React from "react";
import classNames from "class-names";
import Logo from "../logo/logo.jsx";
import styles from "./header.module.css";

const Header = ({
  heroImage = "",
  heroImageMobile = "",
  heroAlt = "",
  heroCredit = "",
  heroImageAspectRatio = "4 / 1", // fallback to "4 / 1"
  heroImageMobileAspectRatio = "3 / 1", // fallback to "3 / 1"
}) => {
  const hasHero = heroImage !== "";
  const hasHeroMobile = heroImageMobile !== "";

  return (
    <header
      className={classNames(styles.header, { [styles.hasHero]: hasHero })}
    >
      <div className={styles.stripe}>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
        </div>
      </div>
      {hasHero && (
        <>
          <img
            className={classNames(styles.hero, {
              [styles.heroDesktop]: hasHeroMobile,
            })}
            src={heroImage}
            alt={heroAlt}
            // Use inline style for aspect-ratio
            style={{ aspectRatio: heroImageAspectRatio }}
          />
          {hasHeroMobile && (
            <img
              className={classNames(styles.hero, styles.heroMobile)}
              src={heroImageMobile}
              alt={heroAlt}
              // Use inline style for aspect-ratio
              style={{ aspectRatio: heroImageMobileAspectRatio }}
            />
          )}
          {heroCredit !== "" && (
            <span className={styles.credit}>{heroCredit}</span>
          )}
        </>
      )}
    </header>
  );
};

export default Header;
