import React from "react";

import UseEmoji from "../emojis/useEmoji.jsx";

import classificationData from "../../../data/survey/classification.json";

import "./dtrend-barchart.css";

/**
 * @param {Array} data - Array of objects: { label, value, maxValue }
 * @param {string} barColor - Color to apply to all data bars
 * Example usage:
 *
 * <DTrendBarChart
 *   data=[
 *     { emoji: "😊", label: "Zuwanderung/Flucht", value: 37, maxValue: 100 },
 *     { emoji: "😊", label: "Wirtschaft", value: 34, maxValue: 100 },
 *     { emoji: "😊", label: "Frieden/Außenpolitik", value: 14, maxValue: 100 },
 *   ]
 * />
 */
function DTrendBarChart({
  data,
  barColor = "var(--brand-secondary)",
  infotext = "",
}) {
  return (
    <div className="dtrend-barchart">
      {data.map((item, index) => {
        const { classification, value, maxValue } = item;
        const { emoji, label } = classificationData[classification];
        const percentage = maxValue ? Math.round((value / maxValue) * 100) : 0;

        return (
          <div key={label} className="dtrend-barchart-row">
            {/* Emoji */}
            <div className="dtrend-barchart-emoji-col">
              <UseEmoji id={emoji} />
            </div>

            {/* Label */}
            <div className="dtrend-barchart-label-col">{label}</div>

            {/* Bar or Text to handle values that are too small to be valid for interpretation */}
            <div className="dtrend-barchart-bar-col">
              {value < 3 ? (
                <div className="dtrend-barchart-placeholder">
                  unter 3 Prozent
                </div>
              ) : (
                <div className="dtrend-barchart-bar">
                  <div
                    className="dtrend-barchart-fill"
                    style={{
                      width: `${percentage}%`,
                      backgroundColor: barColor,
                    }}
                  />
                </div>
              )}
            </div>

            {/* Value */}
            <div className="dtrend-barchart-value-col">
              {value < 3 ? null : `${value}%`}
            </div>
          </div>
        );
      })}
      <div key="infotext" className="dtrend-barchart-infotext">
        {infotext}
      </div>
    </div>
  );
}

export default DTrendBarChart;
