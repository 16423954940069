import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/deutschland-wo-brennts/deutschland-wo-brennts/src/templates/default.jsx";
import Sharing from '../components/sharing/sharing.jsx';
import { Link, LinkList } from '../components/link/link.jsx';
import { QuoteWall } from '../components/quote-wall/quote-wall.jsx';
import Map from '../components/map/map.jsx';
import DTrendBarChart from '../components/dtrend-barchart/dtrend-barchart.jsx';
import DataWrapper from '../components/datawrapper/datawrapper.jsx';
import BarChartSwiperWrapper from '../components/dtrend-barchart/barchart-swiper.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Deutschland, wo brennt's? Hier sind eure Antworten!`}</h1>
    <p>{`Am 23. Februar wird ein neuer Bundestag gewählt. Wir wollten vor einigen Wochen unter anderem von euch wissen: Was sind die wichtigsten politischen Probleme? Welche Situation aus eurem Alltag würdet ihr der neuen Bundesregierung gerne zeigen? Tausende Menschen haben mitgemacht.`}</p>
    <p>{`Hier könnt ihr in den Ergebnissen stöbern. Wischt dafür einfach durch die thematisch sortierbaren Zitate oder klickt euch durch die interaktive NRW-Karte weiter unten.`}</p>
    <h2>{`Probleme, die eine neue Regierung als erstes angehen sollte`}</h2>
    <p>{`Welche Probleme für euch am drängendsten sind, seht ihr hier. Wir haben die Antworten nach den gleichen thematischen Kategorien sortiert, die auch der ARD-DeutschlandTrend verwendet, der repräsentativ ist für alle Wahlberechtigten in Deutschland. Im Menü über den Zitaten könnt ihr ein Thema auswählen, das euch besonders interessiert. Mehr Antworten seht ihr, wenn ihr nach links oder rechts wischt.`}</p>
    <QuoteWall mdxType="QuoteWall" />
    <h2>{`DeutschlandTrend: Das sind die drängendsten Probleme im Land`}</h2>
    <p>{`Unsere Befragung zeigt zwar Eindrücke von vielen Menschen, ist aber nicht nach wissenschaftlichen Kriterien repräsentativ. Anders ist das beim `}<a parentName="p" {...{
        "href": "https://www.tagesschau.de/inland/deutschlandtrend/deutschlandtrend-3454.html"
      }}>{`DeutschlandTrend`}</a>{`, den infratest dimap regelmäßig im Auftrag der ARD durchführt. Auch dort wird nach den wichtigsten Problemen in Deutschland gefragt, um die sich die Politik kümmern muss. Die Befragten können dabei maximal zwei Probleme nennen. Die folgende Grafik zeigt, wieviel Prozent der Befragten das jeweilige Thema für eines der beiden wichtigsten Probleme in Deutschland halten. Das hier sind die Ergebnisse aus der aktuellsten Ausgabe für Deutschland und wenn ihr weiter wischt, seht ihr die Ergebnisse aus dem aktuellen NRW-Trend.`}</p>
    <BarChartSwiperWrapper chartsData={[{
      tabIcon: "GermanFlagEmoji",
      tabLabel: "Deutschland",
      data: [{
        classification: "3",
        value: 37,
        maxValue: 37
      },
      // Zuwanderung/Flucht
      {
        classification: "2",
        value: 34,
        maxValue: 37
      },
      // Wirtschaft
      {
        classification: "4",
        value: 14,
        maxValue: 37
      },
      // Frieden/Außenpolitik
      {
        classification: "5",
        value: 13,
        maxValue: 37
      },
      // Umwelt/Klima
      {
        classification: "6",
        value: 11,
        maxValue: 37
      },
      // Soziale Ungerechtigkeit
      {
        classification: "7",
        value: 8,
        maxValue: 37
      },
      // Bildung
      {
        classification: "15",
        value: 8,
        maxValue: 37
      },
      // Innere Sicherheit
      {
        classification: "10",
        value: 7,
        maxValue: 37
      },
      // Rente
      {
        classification: "14",
        value: 7,
        maxValue: 37
      },
      // Inflation
      {
        classification: "13",
        value: 6,
        maxValue: 37
      },
      // Gesundheit/Pflege
      {
        classification: "9",
        value: 5,
        maxValue: 37
      },
      // Energie
      {
        classification: "8",
        value: 4,
        maxValue: 37
      },
      // Abgehobene Politik
      {
        classification: "16",
        value: 4,
        maxValue: 37
      },
      // Steuern/Abgaben
      {
        classification: "12",
        value: 3,
        maxValue: 37
      },
      // das eigene Land zuerst
      {
        classification: "11",
        value: 3,
        maxValue: 37
      },
      // Arbeitslosigkeit
      {
        classification: "19",
        value: 3,
        maxValue: 37
      },
      // Wohnen/Mieten
      {
        classification: "17",
        value: 3,
        maxValue: 37
      },
      // Rechtspopulismus
      {
        classification: "20",
        value: 3,
        maxValue: 37
      } // Mobilität/Verkehr
      ],
      barColor: "var(--brand-primary)",
      infotext: "An der repräsentativen Befragung haben 1323 Wahlberechtigte in Deutschland teilgenommen, vom 6. bis 8. Januar 2025."
    }, {
      tabIcon: "NRWFlowerEmoji",
      tabLabel: "NRW",
      data: [{
        classification: "2",
        value: 36,
        maxValue: 36
      },
      // Wirtschaft
      {
        classification: "3",
        value: 31,
        maxValue: 36
      },
      // Zuwanderung/Flucht
      {
        classification: "5",
        value: 15,
        maxValue: 36
      },
      // Umwelt/Klima
      {
        classification: "4",
        value: 14,
        maxValue: 36
      },
      // Frieden/Außenpolitik
      {
        classification: "6",
        value: 11,
        maxValue: 36
      },
      // Soziale Ungerechtigkeit
      {
        classification: "14",
        value: 8,
        maxValue: 36
      },
      // Inflation
      {
        classification: "7",
        value: 8,
        maxValue: 36
      },
      // Bildung
      {
        classification: "15",
        value: 8,
        maxValue: 36
      },
      // Innere Sicherheit
      {
        classification: "16",
        value: 7,
        maxValue: 36
      },
      // Steuern/Abgaben
      {
        classification: "10",
        value: 7,
        maxValue: 36
      },
      // Rente
      {
        classification: "9",
        value: 6,
        maxValue: 36
      },
      // Energie
      {
        classification: "13",
        value: 5,
        maxValue: 36
      },
      // Gesundheit/Pflege
      {
        classification: "20",
        value: 4,
        maxValue: 36
      },
      // Mobilität/Verkehr
      {
        classification: "17",
        value: 3,
        maxValue: 36
      },
      // Rechtspopulismus
      {
        classification: "8",
        value: 3,
        maxValue: 36
      },
      // Abgehobene Politik
      {
        classification: "11",
        value: 3,
        maxValue: 36
      },
      // Arbeitslosigkeit
      {
        classification: "12",
        value: 0,
        maxValue: 36
      },
      // das eigene Land zuerst
      {
        classification: "19",
        value: 0,
        maxValue: 36
      } // Wohnen/Mieten
      ],
      barColor: "var(--brand-secondary)",
      infotext: "An der repräsentativen Befragung haben 1150 Wahlberechtigte in Nordrhein-Westfalen teilgenommen, vom 13. bis 16. Januar 2025."
    }]} mdxType="BarChartSwiperWrapper" />
    <h2>{`Diese Situation aus dem Alltag sollten Politiker miterleben`}</h2>
    <p>{`Wir haben gefragt: Du kannst den neuen Bundeskanzler oder die neue Bundeskanzlerin für einen Tag einladen. Welche Situation aus deinem Alltag sollten die Politiker miterleben?`}</p>
    <p>{`Was Menschen aus eurer Nachbarschaft geantwortet haben, könnt ihr in unserer interaktiven Karte sehen.`}</p>
    <Map mdxType="Map" />
    <h3>{`Wie wurden die auf dieser Seite gezeigten Zitate ausgewählt?`}</h3>
    <p>{`Eure Antworten hat ein Team im WDR in den letzten Wochen gesichtet. Dabei haben wir die Antworten den verschiedenen Themenfeldern zugeordnet, die auch die repräsentative Befragung für den ARD-DeutschlandTrend verwendet. Einige wenige Einsendungen haben wir aussortiert, wenn Inhalte z.B. strafrechtlich relevant waren. Wir geben die Zitate in zufälliger Reihenfolge wieder, sie sind nicht redaktionell geordnet. Insgesamt haben wir rund 7000 Antworten erhalten.`}</p>
    <LinkList links={[{
      title: "Alles rund um die Bundestagswahl auf wahl.wdr.de",
      href: "https://www1.wdr.de/nachrichten/bundestagswahl-2025/index.html"
    }, {
      title: "Die jungen Formate aus dem WDR Newsroom",
      href: "https://www1.wdr.de/nachrichten/junge-formate/index.html"
    }, {
      title: "Sendungen und digitale Formate aus dem WDR Newsroom",
      href: "https://www1.wdr.de/nachrichten/newsroom-sendungen-angebote-37.html"
    }, {
      title: "Bleib auf dem Laufenden mit der neuen WDR aktuell App",
      href: "https://www1.wdr.de/nachrichten/meins-wdr-aktuell-app-update-37.html"
    }]} mdxType="LinkList" />
    <Sharing twitter facebook mail whatsapp telegram reddit xing linkedin mdxType="Sharing" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      