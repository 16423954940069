import React, { useRef, lazy, Suspense } from "react";
import { useInView } from "motion/react";

import styles from "./map.module.css";

// Lazy-load actual component
const MapComponent = lazy(() => import("./map-component.jsx"));

export const MapWrapper = () => {
  const wrapperRef = useRef(null);
  const isInView = useInView(wrapperRef, { once: true, margin: "300px 0px" });
  return (
    <div ref={wrapperRef} className={styles.mapWrapper}>
      {isInView ? (
        <Suspense fallback={<Loader />}>
          <MapComponent />
        </Suspense>
      ) : (
        <>
          <p className={styles.scrollHint}>
            Herunterscrollen, um Karte zu laden...
          </p>
          <Loader />
        </>
      )}
    </div>
  );
};

const Loader = () => (
  <div className={styles.loaderWrapper}>
    <span className="loader"></span>
  </div>
);

export default MapWrapper;
