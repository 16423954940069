import React, { useState, useMemo } from "react";

export const WdrPlayerContext = React.createContext({});

export const useWdrPlayerContext = () => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const context = useMemo(
    () => ({
      currentlyPlaying,
      setCurrentlyPlaying,
    }),
    [currentlyPlaying, setCurrentlyPlaying],
  );

  return context;
};
