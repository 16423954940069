import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/swiper-react.mjs";
import Keyboard from "swiper/modules/keyboard.mjs";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Button from "@mui/joy/Button";

import "swiper/swiper.min.css";
import "swiper/modules/keyboard.min.css";

import DTrendBarChart from "./dtrend-barchart.jsx";
import styles from "./barchart-swiper.module.css";
import "../quote-wall/swiper-custom.css";
import UseEmoji from "../emojis/useEmoji.jsx";

const BarChartSwiperWrapper = ({ chartsData }) => {
  const [swiper, setSwiper] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={clsx(styles.wrapper, isExpanded && styles.expanded)}>
      <div className={styles.swiperControls}>
        <ButtonGroup
          variant="outlined"
          orientation="horizontal"
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${chartsData.length}, minmax(auto, 12rem))`,
            margin: "0 1rem",
            boxShadow: "xs",
            height: "3rem",
            width: "fit-content",
            minWidth: "min-content",
            justifyContent: "center",
          }}
        >
          {chartsData.map((chartItem, idx) => (
            <Button
              key={idx}
              onClick={() => swiper.slideTo(idx)}
              variant="outlined"
              aria-label="angezeigte Grafik umschalten"
              // disabled={currentSlideIndex === idx}
              sx={{
                display: "flex",
                boxShadow: "xs",
                borderTop:
                  currentSlideIndex === idx
                    ? `4px solid ${chartItem.barColor}`
                    : "4px solid var(--joy-palette-neutral-outlinedDisabledColor)",
                color:
                  currentSlideIndex === idx
                    ? "var(--brand-font)"
                    : "var(--brand-font-secondary)",
                cursor: currentSlideIndex === idx ? "default" : "pointer",
                ":hover": {
                  backgroundColor:
                    currentSlideIndex !== idx
                      ? "var(--joy-palette-neutral-softHoverBg)"
                      : "#ffffff",
                },
                filter: currentSlideIndex !== idx ? "grayscale(100%)" : "none",
                minWidth: "min-content",
                fontSize: "var(--font-detail)",
                transition: "0.2s ease-in-out",
                transitionProperty:
                  "filter, color, background-color, border-color",
              }}
            >
              <span className={styles.barchartTabIcon}>
                <UseEmoji id={chartItem.tabIcon} />
              </span>
              <span className={styles.barchartTabLabel}>
                {chartItem.tabLabel}
              </span>
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <div className={styles.swiperWrapper}>
        <Swiper
          onSwiper={setSwiper}
          onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
          modules={[Keyboard]}
          spaceBetween={50}
          slidesPerView={1}
          keyboard={{ enabled: true }}
        >
          {chartsData.map((chartItem, index) => (
            <SwiperSlide key={index}>
              <DTrendBarChart
                data={chartItem.data}
                barColor={chartItem.barColor}
                infotext={chartItem.infotext}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <button
        className={clsx(
          styles.expandButton,
          isExpanded && styles.expandButtonExpanded,
        )}
        onClick={() => setIsExpanded(!isExpanded)}
        aria-label={isExpanded ? "Einklappen" : "Ausklappen"}
        title={isExpanded ? "Einklappen" : "Ausklappen"}
      >
        <LucideChevronDown />
      </button>
    </div>
  );
};

BarChartSwiperWrapper.propTypes = {
  chartsData: PropTypes.arrayOf(
    PropTypes.shape({
      tabIcon: PropTypes.string.isRequired,
      tabLabel: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          classification: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
          maxValue: PropTypes.number,
        }),
      ).isRequired,
      barColor: PropTypes.string,
    }),
  ).isRequired,
};

export default BarChartSwiperWrapper;

export function LucideChevronDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 9l6 6l6-6"
      ></path>
    </svg>
  );
}
