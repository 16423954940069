import React from "react";

const EmojiSprite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
    <symbol id="NotoRedQuestionMark" viewBox="0 0 128 128">
      <circle cx="60.9" cy="112" r="10.3" fill="#F44336" />
      <path
        fill="#FFF"
        d="M55 108c1-1 4-3 7-3h2v1h-1c-3 1-5 3-6 5-1 1-2 3-3 2-1 0-1-2 1-5"
        opacity=".2"
      />
      <path
        fill="#C33"
        d="m46 24-4 8c0 2-2 3-4 3h7c2 0 4-1 4-3l4-8q3-3 8-3l-4-1-11 4"
      />
      <path
        fill="#C33"
        d="M90 12c-7-5-15-8-26-8h-3c9 1 16 3 22 8 6 6 9 13 9 23 0 9-4 18-12 26L70 71h-1c-2 3-4 8-5 14 0 2-2 4-4 4h7c2 0 4-2 4-4 1-6 2-11 5-14h1l9-10c9-8 13-17 13-26 0-10-3-17-9-23"
      />
      <path
        fill="#F44336"
        d="M64 85c1-6 3-10 5-14h1l10-10c8-8 12-17 12-26 0-10-3-17-9-23-6-5-13-7-22-8-9 1-16 3-22 8-5 5-9 11-10 19 0 2 2 4 5 4h4c2 0 4-1 4-3l4-8c3-2 7-4 11-4l4 1 3-1c5 0 9 2 11 4s4 7 4 12c0 4-1 7-3 10L66 58 55 71l-2 13c0 3 2 5 4 5h3c2 0 4-2 4-4"
      />
      <path
        fill="#FFF"
        d="M40 16c3-4 10-7 16-8l5 1 1 3-3 1c-6 1-12 5-16 9-2 2-4 7-6 5-3-1-2-6 3-11"
        opacity=".2"
      />
    </symbol>
    <symbol id="NotoFactory" viewBox="0 0 128 128">
      <linearGradient
        id="a"
        x1="31.5"
        x2="37.5"
        y1="7.8"
        y2="35.6"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".4" stopColor="#E0E0E0" />
        <stop offset=".6" stopColor="#C8C8C8" />
        <stop offset=".8" stopColor="#B6B6B6" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M31 37s-1-6 3-10 8-3 13-6 8-8 7-9l-5 2-19 1c-8 0-13 2-15 9-2 5 1 11 1 11z"
        opacity=".7"
      />
      <linearGradient
        id="b"
        x1="67.7"
        x2="73.1"
        y1="9.8"
        y2="35.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".4" stopColor="#E0E0E0" />
        <stop offset=".6" stopColor="#C8C8C8" />
        <stop offset=".8" stopColor="#B6B6B6" />
      </linearGradient>
      <path
        fill="url(#b)"
        d="M52 35s-2-8 2-13 8-6 16-7l14-1c2-1 4-2 5-1s-3 6-10 10c-6 3-9 4-9 8l-2 5z"
        opacity=".7"
      />
      <path
        fill="#CC1935"
        d="m33 39 1-3-3-2H15c-1 0-2 0-2 3l1 2-3 29 11 12h15zm18 0-1-3c0-2 2-2 2-2h17c1 0 2 1 2 3s-2 3-2 3l5 41-25-11z"
      />
      <path
        fill="#F1EDEC"
        d="M14 45h19l1 8H13l1-8m-2 18h23l1 9H10zm39-18h19l1 8H50zm-2 18h23l1 9H48z"
      />
      <path
        fill="#848486"
        d="M4 85V65l2-1 2 1 11 18 21-1V64h2l1 1 6 26-42-2z"
      />
      <path
        fill="#B2B2B2"
        d="M8 85V65l32 17v5H8zm35 2 1-2-1-20 38 19V25h43v94l-22 2H74z"
      />
      <path
        fill="#848486"
        d="M80 120h10V88c0-1 0-2 2-2h22c1 0 2 1 2 3v30h8v3c0 1 0 2-2 2H79z"
      />
      <path fill="#F2EEED" d="M90 81V27l12-3 13 3v54H90" />
      <path
        fill="#A7D0D6"
        d="M93 30h19v14H93zm1 17h17v15H94zm-1 18h19v13H93z"
      />
      <path fill="#848486" d="M79 22v5h46v-6h-22l-24 1" />
      <path fill="#F6B99D" d="M72 85h9v35h-9z" />
      <path fill="#E14536" d="M4 85h74v36H4z" />
      <path fill="#F6B99D" d="M40 85h4v37h-4z" />
      <path fill="#A41916" d="M81 120H4v2c0 1 0 2 2 2h75v-4" />
      <path
        fill="#F1EBEE"
        d="M17 97a686 686 0 0 1 1 11h10l1-3v-8l-2-2h-9c-1 0-2 1-1 2m40-1-2 1v9s0 2 2 2h9c2-1 1-2 1-3v-8l-1-1h-9"
      />
      <path fill="#A7D0D6" d="M57 97h8v9h-8zm-39 1h9v8h-9z" />
    </symbol>
    <symbol id="NotoPersonWalking" viewBox="0 0 128 128">
      <path
        fill="#FFCA28"
        d="M67 13c-2-4-8-7-13-6s-8 5-9 9v5l1 2-3 4 2 2c1 1 0 5 6 6h3l1 3s0 5 4 4c5-2 5-5 5-5s-2-5 1-9c2-4 4-12 2-15"
      />
      <path fill="#795548" d="M46 31h2v-1h-2z" />
      <ellipse
        cx="49.6"
        cy="23.4"
        fill="#404040"
        rx="1.1"
        ry="1.7"
        transform="rotate(-2 50 23)"
      />
      <path fill="#6D4C41" d="m49 21-1-1 2-1 2 2v1s-2-2-3-1" />
      <path
        fill="#616161"
        d="M85 109 71 93l-1-4-2-22-15 1 5 23c1 4 3 8 6 10l16 12z"
      />
      <path
        fill="#7C7C7C"
        d="m68 63-17 1-5 21-1 11 2 24h6l3-25 6-14c4-2 7-5 7-10l-1-8"
      />
      <linearGradient
        id="a"
        x1="51.9"
        x2="69.1"
        y1="77.6"
        y2="65.6"
        gradientTransform="matrix(1 0 0 -1 0 128)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#F78C1F" />
        <stop offset=".5" stopColor="#F47F12" />
        <stop offset="1" stopColor="#EF6C00" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M65 74H53c-2 0-4-3-4-5l2-23c0-4 3-8 5-9l4-2c2 0 4 0 6 2l1 6 2 26c0 2-2 5-4 5"
      />
      <linearGradient
        id="b"
        x1="59.2"
        x2="56"
        y1="57.6"
        y2="76.3"
        gradientTransform="matrix(1 0 0 -1 0 128)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".5" stopColor="#FFCA28" />
        <stop offset="1" stopColor="#FFB300" />
      </linearGradient>
      <path
        fill="url(#b)"
        d="m67 60-2-14-7 2 2 13-4 10-4 2-1 2v3l1 2 1 1 1 1h2l1-1h2l2-3v-5l5-10 1-3"
      />
      <linearGradient
        id="c"
        x1="53.3"
        x2="55.6"
        y1="44.8"
        y2="50.7"
        gradientTransform="matrix(1 0 0 -1 0 128)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".5" stopColor="#FFCA28" />
        <stop offset="1" stopColor="#FFB300" />
      </linearGradient>
      <path
        fill="url(#c)"
        d="m59 79-1-1h-1l-1-1h-1l-1-1h-1v-1l-1-1v-1l-1 2v3l1 2 1 1 1 1h2l1-1h2l2-2h-2"
      />
      <path
        fill="#4568AD"
        d="m55 124 1-1v-3l-2-4h-7l-6 2c-2 1-3 2-3 4v1l1 1z"
      />
      <path fill="#325599" d="M90 112v-2l-2-2-5-1-4 5-3 6v4l1 1h2z" />
      <path
        fill="#EDA600"
        d="M58 79v2h-1v-1l1-1m-2-1v2l-1 2v-2l1-2m-2-1v2l-1 2v-2l1-2m0-3-1 1-1 1-1 2 1-3 1-1h1"
      />
      <path fill="#ED6D23" d="m56 53 11-2-1-10a6 6 0 0 0-7-4 6 6 0 0 0-4 6z" />
      <path
        fill="#543930"
        d="M71 14c-2-4-4-5-5-5-1-1-3-6-10-5l-5 2-1 1h-2V5s-5 1-3 5l-2 4c-1 2 0 4 2 5 0 0 0-3 5-3h4l1-1h1c1 1-1 5 1 7l1 1 1-1 1-2c3 1 2 5 0 6-1 0 0 4 5 4 3 0 4-2 4-2l-1-1 1-2c3-2 2-5 1-6 1 0 2-3 1-5"
      />
      <radialGradient
        id="d"
        cx="63.4"
        cy="103.5"
        r="7.1"
        gradientTransform="matrix(.9381 .3463 .4988 -1.351 -48 142)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".5" stopColor="#6D4C41" stopOpacity="0" />
        <stop offset="1" stopColor="#6D4C41" />
      </radialGradient>
      <path
        fill="url(#d)"
        d="M70 19s-1 2-4 2c-2 0-4 4-2 6s5 1 5 1h-1v-1l1-2c3-2 2-5 1-6"
      />
      <radialGradient
        id="e"
        cx="64.3"
        cy="110.7"
        r="7.2"
        gradientTransform="matrix(.9909 -.1344 -.1696 -1.2504 19 164)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".5" stopColor="#6D4C41" stopOpacity="0" />
        <stop offset="1" stopColor="#6D4C41" />
      </radialGradient>
      <path fill="url(#e)" d="M71 14a8 8 0 0 0-5-5v2l-2 3 2 3v4h3l2-4v-3" />
      <radialGradient
        id="f"
        cx="56.2"
        cy="113.2"
        r="14.9"
        gradientTransform="matrix(.9882 -.1534 -.1153 -.7428 14 108)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".5" stopColor="#6D4C41" stopOpacity="0" />
        <stop offset="1" stopColor="#6D4C41" />
      </radialGradient>
      <path
        fill="url(#f)"
        d="M66 11c0-2-1-4-3-5-2-2-5-2-7-2l-5 2-1 1h-2V5s-5 1-3 5l-2 4c-1 2 0 4 2 5 0 0 0-3 5-3h4c1-1 1-2 3-1h5c3-1 4-4 4-4"
      />
      <radialGradient
        id="g"
        cx="65.1"
        cy="102.6"
        r="8"
        gradientTransform="matrix(.9692 .2463 .1583 -.6231 -14 73)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".5" stopColor="#6D4C41" stopOpacity="0" />
        <stop offset="1" stopColor="#6D4C41" />
      </radialGradient>
      <path
        fill="url(#g)"
        d="m64 27-2-4-2 3c-1 0 0 4 5 4 3 0 4-2 4-2s-3 1-5-1"
      />
    </symbol>
    <symbol id="NotoPeaceSymbol" viewBox="0 0 128 128">
      <path
        fill="#8E24AA"
        d="M116 4H12c-4 0-8 4-8 8v104c0 4 4 8 8 8h104c5 0 8-4 8-8V12c0-4-3-8-8-8"
      />
      <path
        fill="#AB47BC"
        d="M110 4H12a8 8 0 0 0-8 7v98c0 5 4 8 8 8h98c4 0 8-3 8-8V11c0-4-4-7-8-7z"
      />
      <path
        fill="#FAFAFA"
        d="M40 13c0-2-1-3-11-3-7 0-11 1-13 4s-3 9-3 15c0 5 0 10 2 10 3 0 3-8 6-13 6-8 19-10 19-13"
        opacity=".3"
      />
      <path
        fill="#FFF"
        d="M64 16a48 48 0 1 0 0 96 48 48 0 0 0 0-96m40 48c0 9-3 18-9 25L68 62V24c21 2 36 19 36 40m-44 40a40 40 0 0 1-21-9l21-21zm8-30 22 21a40 40 0 0 1-22 9zm-8-50v38L33 89a40 40 0 0 1 27-65"
      />
    </symbol>
    <symbol id="NotoNationalPark" viewBox="0 0 128 128">
      <radialGradient
        id="a"
        cx="48.4"
        cy="86.8"
        r="81"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".3" stopColor="#AFE4FE" />
        <stop offset=".7" stopColor="#84D4F9" />
        <stop offset="1" stopColor="#67C9F6" />
      </radialGradient>
      <path
        fill="url(#a)"
        d="M117 124H11c-4 0-7-3-7-7V11c0-4 3-7 7-7h106c4 0 7 3 7 7v106c0 4-3 7-7 7"
      />
      <path
        fill="#B4D218"
        d="M124 76v41c0 4-3 8-7 8H11c-4 0-7-4-7-8V76l64-2 56 2"
      />
      <path
        fill="#00C1ED"
        d="M106 124H11c-4 0-7-2-7-6l10-4c5-1 19-5 19-12s-14-6-13-13c0-4 6-7 12-9 9-3 19-4 19-4s-4 5-2 10 8 9 20 11 24 5 31 14 6 13 6 13"
      />
      <radialGradient
        id="b"
        cx="55.3"
        cy="66.7"
        r="114.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".3" stopColor="#8CDBFC" />
        <stop offset=".4" stopColor="#54D1F5" />
        <stop offset=".5" stopColor="#23C7EE" />
        <stop offset=".6" stopColor="#10C4EC" />
      </radialGradient>
      <path
        fill="url(#b)"
        d="M8 124h92s-2-6-12-14c-6-5-13-7-20-8-13-3-21-5-23-14-2-5 0-8 0-8s-20 4-20 9 13 4 13 13-12 13-17 14c-9 4-13 8-13 8"
      />
      <path
        fill="#79A5AB"
        d="M4 75h49l-5-8-7-5-17-24c-3-4-7-10-11-10s-9 3-9 3z"
      />
      <path fill="#E0E0E0" d="M4 26h5c3 0 7 3 7 3s-4 0-7 3c-3 2-5 6-5 6z" />
      <path fill="#86B4BB" d="M64 43 51 71l2 4 18-2 12-38-9-2z" />
      <path fill="#3E727B" d="m75 49-4 9-1 11 34 3 14-34-15-22-12 4-10 12z" />
      <path
        fill="#79A5AB"
        d="m124 21-8-6-15-2-10 4-9 10-5 12-3 10s2 2 3-2c1-2 1-6 5-14s11-16 18-15c5 1 7 8 8 13l2 15-8 4-4 13v4l-1 5 20 1 7-5z"
      />
      <path
        fill="#E0E0DF"
        d="m51 68 3 1 4-9 3-6 3-2 5-11 5-6h3l-3 10-3 13 3-2 1-4 3-14 6-12c3-5 5-6 8-8 2-2 7-4 13-4l14 2s-6-4-15-4-14 3-19 8-7 13-7 13-2-2-5-1-7 2-10 7l-8 18zm61-17s-5-1-7 1c-2 1-4 4-5 9l-2 5 1-13c1-2 3-7 7-7 5-2 6 2 6 5"
      />
      <path
        fill="#79A5AB"
        d="M94 27c-2 0-2 1-2 5v7l-4 8-3 8c0 2-1 5 1 5 1 1 3-6 4-11l5-9v-8l-1-5m-8 4-1 4v4l-2 5c0 2 1 2 2 1l3-6v-4c0-1 0-5-2-4"
      />
      <path fill="#ACE4FE" d="m14 41 1 3 1 14v14l5-1V54c0-6-2-8-3-10l-4-3" />
      <path
        fill="#FCFDFE"
        d="M17 51c1-1 1 1 1 4l-1 5-1-5 1-4m2 1v6l1 5v-5l-1-6m-3 20 2-2h2s1-2 4-2 4 4 4 4l-12 2z"
      />
      <path
        fill="#0A7E1D"
        d="M17 76h71l-9-17-6 9-6-10-6 13-3-4-3 5-3-9-7 10-3-1-6 2-9-2-15 3z"
      />
      <path
        fill="#02AB47"
        d="m50 62 2 2-4 10-3-1 5-11m5 10 1 2 3-6-1-1-3 5m12-14-6 13 2 2 5-14-1-1"
      />
      <path
        fill="#0A7E1D"
        d="M83 79v1l15 1 26-1V68l-8-17-7 15-3-5-8 9-6-13-9 22"
      />
      <path
        fill="#02AB47"
        d="m91 54-7 16-4 10h4l6-15 3-7-2-4m13 6 2 2-6 11-2-3 6-10"
      />
      <path
        fill="#0A7E1D"
        d="M4 113s4 0 7-2c4-3 5-6 5-6s1 2 5 2 7-4 7-5l-5-5-5-11 1-3-8-10-3-18-2-1-2 4z"
      />
      <path
        fill="#00AA48"
        d="M12 87c-2 0-4-2-4-8V59c0-3-2-5-2-5h1l7 12 9 17c1 3 2 5 0 5l-5-2 5 9 4 6c1 1-1 2-3 1s-7-8-7-10l-1-4 1-2-2-3-1-3s0 8-2 7"
      />
      <path
        fill="#0A7E1D"
        d="m117 77-19 6c0 1-1 2 1 3l4 1v5l-8 4c-1 1 0 3 2 4h4s-9 7-8 9c0 2 2 5 6 4 4 0 5-1 7 1s2 5 8 4c4 0 5-2 7-3l3-1V80z"
      />
      <path
        fill="#02AB47"
        d="M115 63c-2 0-3 5-7 10L98 83l2 1 5-1c1 0 2 4 4 4l8-3c3-1 3 2 5 2l2-1v-7l-5-8c-1-4-2-8-4-7m-6 27 10-2c4 0 4 5 3 6 0 2-1 5-4 5s-3-2-4-2l-5 2c-2 0-4-2-5-3s-4 2-6 2-3-2-3-2l1-2 5-4 2-3s3 3 6 3m-4 19c3 0 3 4 6 5 4 1 9-7 7-11s-7 1-10 0c-4-1-4-3-7-3s-8 6-8 9c0 1 2 2 5 2l7-2"
      />
      <path
        fill="#FFFFFE"
        d="M20 16c0 4 6 4 15 5 10 0 15-1 15-5s-10-4-16-4-14 1-14 4m22 14c1 3 5 2 10 2 6 0 11 1 12-2 0-4-8-3-11-3s-11 0-11 3"
      />
    </symbol>
    <symbol id="NotoBalanceScale" viewBox="0 0 128 128">
      <path fill="#4C889B" d="M61 29h7v70h-7z" />
      <path
        fill="#4C889B"
        d="M92 36c6 0 11-2 16-8l-6-4c-5 7-13 5-20 2h-1c-5-2-11-3-17-3v7l14 2 1 1c3 1 8 3 13 3m-52 77 15-7c3-2 0-5 1-11 2-7 8-8 8-8s6 1 8 8c1 6-1 9 1 11l15 7c2 3 2 6 0 7 0 0-8 3-24 3s-24-3-24-3c-1-1-2-4 0-7"
      />
      <path fill="#335E68" d="M68 38s0 3-3 4c-2 0-4-1-4-4v-4s7 2 7 4" />
      <path
        fill="#4C889B"
        d="M72 30c0-4-3-17-8-17s-8 13-8 17 3 9 8 9 8-4 8-9"
      />
      <circle cx="64" cy="10.8" r="5.5" fill="#4C889B" />
      <circle cx="104.3" cy="24.2" r="5.5" fill="#4C889B" />
      <path
        fill="#4C889B"
        d="M36 36c5 0 10-2 13-3l1-1 14-2v-7c-6 0-12 1-17 3h-1c-7 3-15 5-20-2l-6 4c5 6 10 8 16 8"
      />
      <path
        fill="#81AFC1"
        d="m59 92-1 3 2 2 5-1c1 0 4-2 3-4 0-2-7-1-9 0m8 25c-7 1-15 0-22-2-2-1-1-2-1-2l4-2 9 2 11 1h1c2 1-2 3-2 3M63 11h-2l-1-2 4-3c2 1 1 4-1 5m-5 16c2 2 5 2 6-2l1-7c0-2-3-3-4-1-3 5-4 9-3 10"
      />
      <circle cx="23.8" cy="24.9" r="1.8" fill="#4C889B" />
      <path fill="#4C889B" d="m3 92 19-68 2-1 2 1 18 68-2 1-17-60h-3L6 93z" />
      <path fill="#81AFC1" d="M44 92H3c0 5 11 11 21 11s20-6 20-11" />
      <ellipse cx="23.8" cy="92.6" fill="#335E68" rx="20.6" ry="3.1" />
      <path
        fill="#A3CDD9"
        d="m24 90 18 2v1l-18 2-18-2v-1l18-2m0-1c-12 0-21 2-21 4s9 3 21 3 20-2 20-3-9-4-20-4m-6 11c2 1 1 1 0 1-2 1-6-1-8-3-1 0-2-2 0-2 4 0 3 2 8 4"
      />
      <path fill="#4C889B" d="M22 25a1 1 0 0 1 3 0v71a1 1 0 0 1-3 0z" />
      <circle cx="23.8" cy="24.5" r="5.5" fill="#4C889B" />
      <path
        fill="#81AFC1"
        d="M25 20v2l-3 2-1 2a1 1 0 0 1-1-1c-1-2 0-3 2-4l3-1"
      />
      <path fill="#4C889B" d="m84 92 19-68 1-1 2 1 19 68-3 1-16-60h-3L86 93z" />
      <path fill="#81AFC1" d="m125 93-41-1c0 5 10 11 20 11s21-6 21-10" />
      <ellipse
        cx="104.2"
        cy="92.6"
        fill="#335E68"
        rx="3.1"
        ry="20.6"
        transform="rotate(-90 104 93)"
      />
      <path
        fill="#A3CDD9"
        d="m104 90 18 2v1l-18 2-18-2v-1l18-2m0-1c-11 0-20 2-20 3 0 2 9 4 20 4s21-2 21-3c0-2-9-4-21-4m-6 11c2 1 1 1 0 1-2 0-6-1-8-3-1-1-2-3 1-2l7 4"
      />
      <path fill="#4C889B" d="M103 25a1 1 0 1 1 3 0v71a1 1 0 1 1-3 0z" />
      <path fill="#81AFC1" d="M105 20v2l-2 2h-2c-1 0-1-2 1-3l3-1" />
    </symbol>
    <symbol id="NotoGraduationCap" viewBox="0 0 128 128">
      <path
        fill="#424242"
        d="M99 91s-15 15-35 15-35-15-35-15-3-13 4-36c4-13 59-14 63-3 7 18 3 39 3 39"
      />
      <path
        fill="#212121"
        d="m29 91 5 4s0-7 2-14c1-3 4-4 6-3l19 12a6 6 0 0 0 6 0l33-20v-4H29z"
      />
      <path fill="#424242" d="M123 52 66 86h-4L5 52v-4l57-34h4l57 34v4" />
      <path fill="#9E9E9E" d="M64 83 4 49v2l1 1 57 34h2v-3z" />
      <path fill="#616161" d="M64 83h1l59-34v2l-1 1-57 34h-2v-3z" />
      <path
        fill="#424242"
        d="m35 70 1-3 27-14c3-1 5-3 6-5 2-2 0-3 0-3s-1 2-5 2c-2 0-5-2-5-3L31 64v3z"
      />
      <path
        fill="#E2A610"
        d="M69 45c-2 3-7 2-7 2s-14 6-14 5l11-7v-3L29 60c-4 3-5 5-5 8a1820 1820 0 0 0-1 10 5 5 0 0 0-2 4l2 4v6l-1 3-1 4c-1 6-2 14-5 20a1 1 0 0 0 0 1 1 1 0 0 0 2 0l3-11v13c3 1 3-3 3-3s0 4 3 4 3-4 3-4 1 3 2 3 2 0 1-4l-1-19c0-6-2-9-3-11l1-2a5 5 0 0 0 0-8 2 2 0 0 1-1-1l1-7c1-4 3-5 5-6l32-15c3-2 2-4 2-4"
      />
      <path
        fill="#FFCA28"
        d="M69 45c-2 3-7 2-7 2l-12 4 9-6v-3L28 61c-2 1-5 4-3 6 1 2 3 2 5 1l2-2 3-2 32-15c3-2 2-4 2-4"
      />
      <path fill="#9E740B" d="M26 92h-3a1 1 0 0 1 0-1v-4h1l5-1 1 1v4l-4 1" />
      <path
        fill="#616161"
        d="M99 91s2-9 1-19c0-1-1-3-2 3l-3 20 4-4M66 37l1 1c1 1 0 2-1 3-1 2-3 3-5 3l-2-1v-1c0-4 4-6 7-5"
      />
    </symbol>
    <symbol id="NotoAirplaneDeparture" viewBox="0 0 128 128">
      <path
        fill="#5E6268"
        d="M57.9 96c-2.2 0-37.3 9.6-37.9 9.8-.7.2-1 1.2.3 1.6s59.5 16.4 60 16.4 32-14.5 32.5-14.8c.6-.2-.1-1.6-1.1-2-1-.3-51-11-53.8-11"
      />
      <path fill="#C8C8C8" d="m58.1 117.8 37-14.4 5.7 1.3-37 14.5z" />
      <path
        fill="#C8C8C8"
        d="m96.5 105.5 16.3 3.5-3.6 1.7-17.5-4zm-6.8 2.6 16.5 4-3.8 1.7-17-4zm-9.2 3.3 17.4 4.5-4.1 1.9-17.3-4.6zM67 117l17.9 4.8 5-2.3-18-4.3zM56 102l-4.8-1.1-7 1.6 5.5 1.4 6.2-2m11.5 2.7-6-1.4-5.9 2.1 6 1.5zm5.1 1.2-5.8 2.3 5 1.3 6.3-2.4-5.5-1.2"
      />
      <path
        fill="#014EAC"
        d="M23.6 47.5c0 .5 9.6 18.8 9.8 18.7l7-1.2 4.3-4.7S28.5 44.5 27.9 44.5c-.5 0-4.2 2.4-4.3 3"
      />
      <path
        fill="#006CA8"
        d="m99.4 51 10.5-.8c4.9-.4 9.9-1.5 9.9-1.5s0-2.2 0-2.6c.4-.7 1.8-1.7 2.2-1.7s2.1 2 3 3.7c1 2 .2 3.5-2 4.5-4.2 2-13.5 5-29.6 6.9-24.6 2.8 6-8.6 6-8.6"
      />
      <path
        fill="#B0B0B0"
        d="M51.1 66.6 33.6 73s16.8 1.7 23.6 0c4-1 16.7-5.4 35.4-16A94.6 94.6 0 0 0 118.8 38c1.5-1.8 2-3.7 1.1-4.9-.9-1-3.4-1-3.4-1z"
      />
      <path
        fill="#C8C8C8"
        d="M29.6 68.7s1.6-1.5 3.4-2.9c4.5-3.4 8-4.9 8-4.9l10.8-7.2s44.8-22.7 47.5-24 5.4-2.1 8.5-1.8a7 7 0 0 1 4.2 1.7l1.8 2s2-.1 4.2.5c1.5.4 2 1 2 1s-16.4 12.5-29 20-33.6 19-42.5 20-18.3-.5-18.3-.5z"
      />
      <path
        fill="#006CA8"
        d="M11.1 71.2c.1 1.5 1 1.5 5.9 1.5l15.7.1c.7 0 3.8-1.2 4-3.4 0-1.6-.4-2.3-1.5-2.3s-2.5.7-4.1 1-14.8 1.5-16.5 1.6c-1.6.1-3.5.1-3.5 1.5"
      />
      <path
        fill="#9A9A9A"
        d="M95.2 60.4s2.4-2.6 4.8-4.7c2.4-2 5.7-4.4 6.7-4.5 2 0 5.2 1 6 4.5s-1.1 5.4-2 6c-1.3.9-7.4 5.2-11.4 4.3-5-1-4-5.6-4-5.6"
      />
      <path
        fill="#5E6269"
        d="M106.7 52.8c-1 .5-1.8 2.2-.4 5 1.4 2.7 3.1 3 4.2 2.3 1-.8 1-3.3.2-5-.9-1.8-2.8-2.8-4-2.3"
      />
      <path
        fill="#3B5361"
        d="M70 50c-1.1.3-1.1 1.7-1 2.2.3 1.4 2.3 1.2 2.3 1.2s.7-1.2.4-2.3c-.2-.6-.7-1.4-1.7-1.1"
      />
      <path
        fill="#006CA8"
        d="M4.4 50.9c-1.1 0-1.3 6-1.2 7.4 0 1.4 1 2.5 3.6 2.8s44.6 1.4 49.2 1.4 16-2.2 16-2.2 5.7-3.2 7.4-4.3c1.7-1.2 5.4-3.2 3.3-4.7-.8-.6-17.3 1.5-35.4 3-19.1 1.4-29 2.6-38.9 2.2 0 0-3-5.7-4-5.6"
      />
      <path
        fill="#9A9A9A"
        d="M55.4 65.8c1.8 3.4 6.4 3 9.2 1.6 2.9-1.3 6.7-3.1 7.1-3.5s1.7-3.9-.5-7.6-5-3.7-6-3.6c-.7.1-5.4 3.2-7.3 4.8-2 1.6-4.4 4.9-2.5 8.3"
      />
      <path
        fill="#5E6269"
        d="M65.4 54.3c-1.6.5-1.4 4 0 6.2s3.1 3.3 4.4 2.7c1.4-.6 1.2-3.2 0-5.6-1.2-2.6-3-3.7-4.4-3.3"
      />
      <path
        fill="#3B5361"
        d="M112 29.7s-1.8-.1-5 1.9-4.2 4.2-3.3 4.8c1 .7 2.8-1.3 5.6-2.8 2.2-1.1 4.5-2 4.5-2zm-35.6 17c-.9.2-1.2 1.2-1 2.2s.8 1.5 1.5 1.3c.7-.3 1.2-.9 1.1-2 0-1-.6-1.7-1.6-1.5m6.3-3.4c-1.1.4-1.2 1.3-1 2 0 1.1.8 1.6 1.6 1.4s1-1.1 1-2c-.2-1-.7-1.7-1.6-1.4m6.4-3.3c-.9.3-1.3 1-1.2 2.2.1 1 .8 1.9 1.6 1.5.9-.3 1.2-1 1-2.3 0-1-.6-1.6-1.4-1.4m6-2.9c-.9.4-1.1 1.1-1.1 2 0 1 .8 1.9 1.6 1.6 1-.3 1.3-1.5 1.2-2.2 0-.9-.7-1.7-1.6-1.4M44.8 63.8c-.7.3-1.2 1-1 2s1 1.3 1.5 1.3c.6 0 1.2-.9 1-2 0-1-.8-1.6-1.5-1.3"
      />
      <path
        fill="#E0E0DF"
        d="M96.6 32.6c-.8.4-32.3 17.2-33 17.7s-1 1-.7 1.4 1.3 0 2-.2S97 34.6 97.4 34.4c.4-.3 1-.9.7-1.6-.3-.6-1-.4-1.6-.1m3-1.7c-.5.3-1 .8-.7 1.4s1 .5 1.6.2c.5-.3 1-.8.7-1.3s-1-.6-1.5-.3"
      />
    </symbol>
    <symbol id="NotoHighVoltage" viewBox="0 0 128 128">
      <path
        fill="#FEB804"
        d="M70 54 99 6c0-2 1-3-1-3S51 49 51 49L19 68s-2 2-2 3l3 2h37c2 0 2 3 2 3l-22 34-9 14c0 1 1 2 3 1l78-67c1-1 1-4-3-4s-32 8-32 8z"
      />
      <path
        fill="#FFC927"
        d="M65 50c-1 2-2 4 0 4h41L70 87l-38 34-4 3c-1 0-1-1 2-5l26-44 2-3s2-5-1-5l-38 1 31-26L98 3 65 50"
      />
      <path
        fill="#FFE567"
        d="M63 71c2-3 4-3 5-2 1 0 2 2 0 5l-24 31a1747 1747 0 0 1 19-34m-28-6c-4 3-7-1-5-3l21-18a782 782 0 0 1 24-18L56 46 35 65"
      />
    </symbol>
    <symbol id="NotoEuroBanknote" viewBox="0 0 128 128">
      <path
        fill="#1565C0"
        d="M120 102H8c-2 0-4-2-4-4V30c0-2 2-4 4-4h112c2 0 4 2 4 4v68a4 4 0 0 1-4 4"
      />
      <path
        fill="#29B6F6"
        d="M120 90H8a4 4 0 0 1-4-4V30c0-2 2-4 4-4h112c2 0 4 2 4 4v56a4 4 0 0 1-4 4"
      />
      <circle cx="84.8" cy="57.9" r="18.5" fill="#1565C0" opacity=".5" />
      <path
        fill="#1565C0"
        d="M106 33c1 6 6 10 11 11v28c-5 1-10 5-11 11H21c-1-5-5-9-10-10V44c6-1 10-5 11-11zm2-2H20v1c0 6-5 10-11 10v33c6 0 10 4 11 10h88c0-6 5-11 11-11V42c-6 0-11-5-11-11"
      />
      <path fill="#FFECB3" d="M53 26h22v76H53z" />
      <path fill="#FFC06C" d="M53 90h22v12H53z" />
      <path
        fill="#FFF"
        d="M42 62h-8v1c0 3 0 5 2 6 1 2 3 3 6 3l4-1 1 1v3l-5 1c-4 0-7-1-10-4-2-2-3-5-3-9l-1-1h-3v-3h4v-2l-1-1h-3v-3h4c0-4 1-7 4-9 2-3 5-4 9-4l4 1 1 1v2l-1 1a17 17 0 0 0-4-1l-6 2c-2 2-2 4-2 7-1 0 0 0 0 0h8l1 1v2h-9v3h8l1 1v2h-1"
      />
    </symbol>
    <symbol id="NotoPostbox" viewBox="0 0 128 128">
      <path
        fill="#C62828"
        d="M33 4h63c2 0 4 1 5 3l4 10H23l5-10a6 6 0 0 1 5-3"
      />
      <path fill="#78A3AC" d="M53 87h22v37H53z" />
      <path fill="#4B727B" d="M75 95H53v19s3-2 11-1c7 1 11 7 11 7z" />
      <path
        fill="#F44336"
        d="M102 108H26c-2 0-3-2-3-4V19c0-2 1-3 3-3h76c2 0 3 1 3 3v85c0 2-1 4-3 4"
      />
      <path
        fill="none"
        stroke="#C62828"
        strokeMiterlimit="10"
        strokeWidth="1.9"
        d="M95 100H33a3 3 0 0 1-3-4V65c0-2 2-3 3-3h62c1 0 3 1 3 3v31a3 3 0 0 1-3 4z"
      />
      <path
        fill="#C62828"
        d="m45 82 2 2h49l2-2-7-37s5 0 6-2 0-3-1-6c0-2-7-8-7-8l-53 3 5 11h7v12z"
      />
      <path fill="#4E342E" d="M85 44H43a2 2 0 0 1-2-2v-6h46v6l-2 2" />
      <path
        fill="#FFF"
        d="m82 32 10 39c0 2-1 2-2 2H39c-1 0-2 0-2-2l9-39 2-2h32c1 0 2 0 2 2"
      />
      <path fill="#E0E0E0" d="M85 42H43l3-9 36-1z" />
      <path
        fill="#6FBFF0"
        stroke="#6FBFF0"
        strokeMiterlimit="10"
        d="M90 71 64 55l3-3c2-1 2-4 0-6l-3-3 16-11 1-1h-1L62 42 49 31h-1l17 16c1 1 1 3-1 5L39 71l-1 1h1l22-15 28 14h1c0-1 0 0 0 0z"
      />
      <path
        fill="#F44336"
        d="M88 36H38a2 2 0 0 1-2-3v-1l54-5 1 6a2 2 0 0 1-3 3"
      />
      <path fill="#FF7555" d="M87 33H40c-2 0-3-1-3-3l2-3h49l1 4c1 1 0 2-2 2" />
      <path fill="#FF9D83" d="M39 27h3v5l-2 1h-3v-2l2-4" />
      <path fill="#4E342E" d="M94 63h-4l-1-2h5z" opacity=".3" />
    </symbol>
    <symbol id="NotoAmbulance" viewBox="0 0 128 128">
      <path
        fill="#E1E1E1"
        d="M48 112h37l40-17V70l-5-33v-6c0-2-1-3-5-3H47L34 41 19 66 7 75l-1 4v17z"
      />
      <path
        fill="#516C73"
        d="m125 95-26 1c-5 1-7 4-10 8l-4 8h37c1 0 3-2 3-5V95m-77 17s-3-8-6-11-8-6-14-6H6s-4 3-4 7c0 5 0 9 3 9l43 1"
      />
      <path
        fill="#4E433D"
        d="M15 112c1 5 4 12 13 12s12-6 12-13c0-6-6-11-13-11-7 1-12 6-12 12"
      />
      <path
        fill="#C8C8C8"
        d="M21 112c0 3 2 6 7 6s6-3 6-6c0-4-3-6-7-6-3 0-6 3-6 6"
      />
      <path
        fill="#4E433D"
        d="M94 112c0 5 4 11 12 11s12-6 12-12c0-7-5-11-12-11s-12 5-12 12"
      />
      <path
        fill="#C8C8C8"
        d="M100 112c0 2 2 6 6 6s7-3 6-7c0-3-2-6-6-6s-6 3-6 7"
      />
      <path fill="#D70617" d="M6 91h119v-9H6z" />
      <path fill="#FFE365" d="M6 79h13l1 1c0 1 0 4-2 7l-6 1H6v-9" />
      <path fill="#C9C9C9" d="M34 41h87v-2l-1-2H47v-9l-3 1-6 6-4 6" />
      <path fill="#FA2B23" d="M37 32c-2 2-1 7-1 7l7-1 1-9s-4-1-7 3" />
      <path fill="#DD0F26" d="M47 28h23v-6c0-2-1-3-4-3H51c-2 0-4 1-4 4v5" />
      <path
        fill="#FF5B5E"
        d="m55 23 2 3h-3l-3-3 3-2h3zm6 0-1 3h3l2-3-2-2h-3z"
      />
      <path fill="#1E86FE" d="m91 45-1 16 1 15h7V45z" />
      <path fill="#1E86FE" d="m80 65 12-8 13-9 4 6-12 9-13 8z" />
      <path fill="#1E86FE" d="m83 49-4 6 13 9 12 8 5-6-13-9z" />
      <path
        fill="#557077"
        d="M38 46c-1 0-3 0-4 2L23 67l-1 4 2 1h31c2 0 2-1 2-3V48c0-2-1-2-3-2H38"
      />
      <path fill="#AFE3FB" d="m40 50-4 2-8 15 1 1h23c1 0 2-1 2-3V52l-2-2H40" />
    </symbol>
    <symbol id="NotoChartIncreasing" viewBox="0 0 128 128">
      <path fill="#FFF" d="M4 4h120v120H4z" />
      <path
        fill="none"
        stroke="#B0BEC5"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M25 4v120M44 4v120M64 4v120M84 4v120M103 4v120M124 25H4m120 19H4m120 20H4m120 20H4m120 19H4"
      />
      <path
        fill="#E53935"
        d="M5 123v-6l27-49 24 22 63-85h4v5L56 99 33 78 8 123z"
      />
      <path fill="#B0BEC5" d="M122 6v116H6V6zm2-2H4v120h120z" />
    </symbol>
    <symbol id="NotoMoneyBag" viewBox="0 0 128 128">
      <path
        fill="#FFCA28"
        d="M93 39c7-1 16-8 17-11 1-2-3-5-4-6-2-3-1-4 0-8 1-3-2-7-5-9s-8-1-12 0-6 5-9 7c-2-1-6-7-11-3-4 2-5 8-4 13 1 11 4 16 9 18 6 2 13 1 19-1"
      />
      <path
        fill="#E2A610"
        d="M104 8c0 15-15 25-22 29l5 4 6-2c7-2 17-8 17-11 1-5-4-4-5-8-1-2 3-6-1-12m-24 4-5-4-2 6c0 3 0 8 2 12h1c1-8 4-14 4-14"
      />
      <path
        fill="#FFCA28"
        d="M62 38s-31 4-45 31-2 43 10 49 45 8 66 3 26-15 25-25c-2-14-15-23-15-23s0-18-13-29c-13-10-28-6-28-6"
      />
      <path
        fill="#6B4B46"
        d="M75 84c-7-9-15-10-17-13-2-2-3-3-2-5 0-2 3-4 5-4 2-1 7-1 12 4l1 4c0 3 4 6 8 3 3-2 1-8-2-11-2-2-8-7-16-7-2-1-11-2-16 8-2 3-2 10 6 15 1 2 10 6 12 9 4 5 1 9-2 9-9 2-14-3-14-5l-1-6c-1-2-3-2-5-2-6 2-4 9-1 13 3 3 6 6 10 7 15 5 22-3 24-8 0-4 0-8-2-11"
      />
      <path
        fill="none"
        stroke="#6B4B46"
        strokeMiterlimit="10"
        strokeWidth="5"
        d="M71 49c-13 27-15 61-15 61"
      />
      <path
        fill="#6D4C41"
        d="M82 32c8 3 10 10 9 12 0 3-10-7-24-6-4 0-3-3-1-5 3-2 8-3 16-1"
      />
      <path
        fill="#6B4B46"
        d="M82 32c8 3 10 10 9 12 0 3-10-7-24-6-4 0-3-3-1-5 3-2 8-3 16-1"
      />
      <path fill="#E2A610" d="M96 59c2-1 5 0 6 7l1 7-6-6c-2-4-2-7-1-8" />
    </symbol>
    <symbol id="NotoMegaphone" viewBox="0 0 128 128">
      <path
        fill="#82AEC0"
        d="m66 111-14 1c-4 0-6-1-7-6l-4-8 7-1 3 7a2 2 0 0 0 2 1l10-1c3 0 4-2 3-3l-3-8 8-3 3 9c1 3 2 6 1 8-2 3-4 4-9 4"
      />
      <path
        fill="#2F7889"
        d="m42 98 1 5 3-1c3-1 4 1 4 1l-2-5zm22-1 9-1 2 5s-2-2-5-1c-3 0-4 2-4 2z"
      />
      <ellipse
        cx="15.3"
        cy="86.2"
        fill="#B8DADE"
        rx="9.9"
        ry="17.9"
        transform="rotate(-22 15 86)"
      />
      <path
        fill="#82AEC0"
        d="M27 95c-2-2-8 2-8 2 0 5-6 3-6 3 3 3 6 4 9 3s4-4 5-9"
      />
      <path
        fill="#F44336"
        d="M81 15 13 70c-2 1-4 9 0 17s9 13 12 12l87-6c10-3 10-24 2-46S90 11 81 15"
      />
      <path
        fill="#C62828"
        d="M95 81c3 4-2 4-22 8l-54 8c2 1 4 3 6 2l87-6zM73 28c2-1 4-3 4-6l1-3 3-5-68 56-2 3 62-45"
      />
      <ellipse
        cx="100.5"
        cy="52.5"
        fill="#B8DADE"
        rx="18.1"
        ry="44.8"
        transform="rotate(-22 100 52)"
      />
      <ellipse
        cx="102.6"
        cy="51.6"
        fill="#2F7889"
        rx="11.9"
        ry="37.1"
        transform="rotate(-22 103 52)"
      />
      <linearGradient
        id="a"
        x1="115.7"
        x2="103.2"
        y1="83.1"
        y2="52.9"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#353738" />
        <stop offset="1" stopColor="#353738" stopOpacity="0" />
      </linearGradient>
      <path fill="url(#a)" d="M116 86c6-2 5-20-2-39l-22 9c7 19 18 33 24 30" />
      <path
        fill="#FFF"
        d="M80 38c-2-6-1-11-1-15l2-3c2 1 2 2 2 3-1 4 0 9 1 14 0 1 0 2-2 3l-2-2"
      />
      <path
        fill="#FFCA28"
        d="m15 75 56-37c1-1 4-3 4 1v7c0 2-4 3-5 4L15 79c-1 0-2 1-2-1l2-3"
        opacity=".5"
      />
    </symbol>
    <symbol id="NotoBabySymbol" viewBox="0 0 128 128">
      <path
        fill="#194FA5"
        d="M116 4H12a8 8 0 0 0-8 8v104a8 8 0 0 0 8 8h104a8 8 0 0 0 8-8V12a8 8 0 0 0-8-8"
      />
      <path
        fill="#2262DD"
        d="M109.7 4H11.5A7.6 7.6 0 0 0 4 11.5v97.9c0 4.1 3.3 7.5 7.5 7.5h98.1c4.1 0 7.5-3.3 7.5-7.5V11.5c0-4-3.1-7.4-7.2-7.5z"
      />
      <path
        fill="#68ABE6"
        d="M39.7 12.9c0-2.3-1.6-3-10.8-2.7-7.7.3-11.5 1.2-13.8 4-1.9 2.3-2.3 5.6-2.6 8.4-.2 2.2-2.2 14.9 3.5 11.2.7-.5 1.2-1 1.6-1.8 1.2-2.1 1.9-3.5 3.3-5.6 5.3-8.6 18.8-10.5 18.8-13.5"
      />
      <g fill="#FFF">
        <circle cx="62" cy="28" r="15" />
        <path d="M103.3 48.1a5.5 5.5 0 0 0-7.3-.5l-6.6 4.9c-.8.6-1.8.7-2.7.3l-14.4-6.2-.7-.3A42.4 42.4 0 0 0 60.9 45c-2.7.2-6 .6-8.5 1.3l-.7.3-14.4 6.2c-.9.4-1.9.3-2.7-.3l-6.6-5a5.5 5.5 0 0 0-7.3.6 5.4 5.4 0 0 0 .8 8l10.4 7.7c1 .7 2 1 3.1 1 2.6 0 9.8-2.7 9.8-2.7a.9.9 0 0 1 1.1.9V68c0 .6.5 1 1 1h30.2c.5 0 1-.4 1-1V63a.9.9 0 0 1 1.1-.9S86.4 65 89 65c1 0 2.1-.4 3-1.1l10.5-7.7a5.4 5.4 0 0 0 .8-8M78.8 78l.5-.8c.4-.4.5-1 .5-1.5v-1.6c0-1-.6-1.6-1.4-1.8H45.6c-.8.1-1.4.9-1.4 1.8v1.6c0 .4 0 .8.2 1.1l9.4 13c.7 1 1.7 1.5 2.9 1.5h10.6c1.2 0 2.2-.6 2.9-1.5L78.8 78zM36 85a8.5 8.5 0 0 0 0 12l10 10.1a6.4 6.4 0 0 0 9.3-.1 6.6 6.6 0 0 0-.4-9.1L48.2 91l.8-1c.3-.4.4-1 0-1.3L42.8 80a1 1 0 0 0-1.5 0zm52 0a8.5 8.5 0 0 1 0 12l-10 10.1a6.4 6.4 0 0 1-9.3-.1 6.6 6.6 0 0 1 .4-9.1l6.7-6.8-.8-1a1 1 0 0 1 0-1.3l6.3-8.8a1 1 0 0 1 1.5 0z" />
      </g>
    </symbol>
    <symbol id="NotoHouses" viewBox="0 0 128 128">
      <path fill="#DE7541" d="M42.2 41.6V31h-.8v-6.5h8.2v6.4h-.8V37z" />
      <path fill="#FFF3E1" d="m63.9 29.8-21 20 1 24.7 42.3-.3-.4-26z" />
      <path
        fill="#5F4E47"
        d="m42.2 74.6 2-1.2v-24l-1.9 1.4s.2 23.8-.1 23.8m42-24.3v23.3l2.2 1.3V51z"
      />
      <path
        fill="#BDA890"
        d="M64.4 31.2 42.4 52l-.2-5.6L64.7 25l21.8 22.5v5z"
      />
      <path
        fill="#904625"
        d="M40 45.3 39 48.6h3.4S64 27.3 64.5 27.3c.6 0 22 21.2 22 21.2h3L65.8 22.8l-3.4-.3z"
      />
      <path
        fill="#DC7540"
        d="M36 47c-.7.6-.3 1.5.3 1.5h3.5l23.7-23.9s.5-.5 1-.5c.3 0 .9.6.9.6l23.9 23.8h2.3c1 0 1.1-1.1 0-2L67.2 22c-1.2-1.2-1.8-1.9-2.9-1.9-1.1 0-1.8 1-2.9 2L35.9 46.9"
      />
      <path
        fill="#D27857"
        d="m56.9 72.5.2-17.5c.2-3.3 3.2-6 6.7-6 4.6-.2 6.8 2.6 7 5.8l.1 17.7z"
      />
      <path
        fill="#FFBA02"
        d="M66.6 62.5c0 1 .4 1.6 1.4 1.6s1.4-.5 1.5-1.4c0-.9-.5-1.6-1.5-1.6s-1.3.8-1.4 1.4"
      />
      <path
        fill="#A6CFD6"
        d="M60.2 44.8v-5.6c0-1.8 1.8-3.3 3.8-3.3 2.4-.1 3.7 1.4 3.8 3.2v5.7z"
      />
      <path fill="#AB5832" d="M11.2 73.6V63.4h-.9v-6.3h8v6.2h-.8v6z" />
      <path fill="#EDE0D6" d="M33.2 61.9 11 83.2v25.1l45-.3.5-26.7z" />
      <path
        fill="#5F4E47"
        d="m10.1 109.7 2.2-.2V82.7l-2 1.4-.2 25.6m44.7-26.1-.1 24.8 2.5 1.5V84.5z"
      />
      <path fill="#BCAFAA" d="m33.7 63.3-23.5 22v-6l23.9-22.6 23.1 24V86z" />
      <path
        fill="#7F4935"
        d="m7.9 78.3-.3 3.5h2.6s23.1-22.6 23.6-22.6c.7 0 23.4 22.6 23.4 22.6h3l-25-27.5-3.6-.2z"
      />
      <path
        fill="#BB8064"
        d="M3.5 80c-.7.7-.3 1.8.3 1.8h3.8l25.2-25.5s.6-.6 1-.5c.5 0 1 .6 1 .6l25.4 25.4h2.5c.8 0 1.1-1.1 0-2l-26-26.2c-1.3-1.3-2-2.1-3.1-2s-2 .9-3.1 2L3.5 80"
      />
      <path
        fill="#865C51"
        d="m25.8 108.3.1-19.7c.2-3.5 3.4-6.3 7.3-6.4 4.8-.2 7.1 2.8 7.3 6.2s.2 20 .2 20z"
      />
      <path
        fill="#FFB700"
        d="M36.1 96.7c0 .9.4 1.7 1.5 1.7s1.5-.6 1.6-1.6c0-.9-.6-1.7-1.6-1.6s-1.4.8-1.5 1.4"
      />
      <path fill="#BEB4AC" d="m10.1 109.7 2.2-2h42.4l2.5 2.2z" />
      <path
        fill="#6CA4AE"
        d="M29.3 77.8v-6c0-2 2-3.5 4-3.5 2.6 0 4 1.5 4 3.4l.1 6z"
      />
      <path fill="#AB5832" d="M110.2 73.7V63.4h-.8v-6.3h8v6.2h-.9v11z" />
      <path fill="#F5E1D1" d="M94.1 62 72 83.5l1.5 25.8 43.7.4.4-28z" />
      <path
        fill="#5F4E47"
        d="m71 110.3 2.2-.8V82.9l-2 1.4s.2 26-.2 26m44.8-26.5v25.6l2.4 1.2V84.7z"
      />
      <path fill="#D2A67C" d="M94.7 63.5 71.2 86.3v-6.8L95 56.9l23.1 24v5.3z" />
      <path
        fill="#893F2E"
        d="m68.8 78.5-.4 3.4 2.8.1s23.1-22.6 23.6-22.6a791 791 0 0 1 23.4 22.5h3l-25-27.4-3.6-.2z"
      />
      <path
        fill="#C67260"
        d="M64.5 80.3c-.7.6-.4 1.5.3 1.6h3.6l25.4-25.4s.5-.6 1-.5c.4 0 1 .6 1 .6l25.4 25.3h2.5c1 0 1.3-1 0-2.1l-26-26c-1.3-1.3-2-2-3.1-2s-2 .9-3.1 2l-27 26.5"
      />
      <path
        fill="#AB5832"
        d="m86.7 108.5.2-19.7c.1-3.5 3.4-6.3 7.2-6.4 5-.1 7.2 2.8 7.4 6.3s.2 19.8.2 19.8z"
      />
      <path
        fill="#FEB800"
        d="M97.1 96.9c0 1 .3 1.7 1.5 1.7s1.5-.5 1.6-1.6c0-.9-.6-1.7-1.6-1.6s-1.5.8-1.5 1.5"
      />
      <path fill="#D4A37D" d="m71 110.3 2.2-2h42.6l2.4 2.3z" />
      <path
        fill="#6CA4AE"
        d="m90.2 78 .1-6c.1-2 1.9-3.5 4-3.5 2.6 0 4 1.5 4 3.4v6z"
      />
    </symbol>
    <symbol id="NotoBus" viewBox="0 0 128 128">
      <path fill="#3E717A" d="m47 50 1-4c0-1 0-5 2-5h30c2 0 3 2 3 3v6H47" />
      <path
        fill="#6BA4AB"
        d="m41 48-2-2c-1-1-21-2-27 6S5 76 5 83L4 94l69 3 52-2V68c-1-6-1-15-4-17s-4-2-10-2l-70-1"
      />
      <path
        fill="#E0E0E0"
        d="m4 93 121 1 1 17c0 2-1 3-3 3l-98 1-20-1s-3-1-3-3V98z"
      />
      <path fill="#FF8D02" d="m2 106 3-1c1-2 2-9 1-10H4l-2 5v6" />
      <path
        fill="#868486"
        d="M32 115v-7c-1-3-3-10-14-10-9-1-13 7-13 11v5l13 1h14m54 0 1-10c3-4 6-7 13-7s10 4 12 6c2 5 1 10 1 10z"
      />
      <path
        fill="#4E433D"
        d="M6 113c1 4 4 11 12 11s12-6 12-12c0-7-5-11-12-11s-12 5-12 12"
      />
      <path
        fill="#B2B1B2"
        d="M12 112c0 3 2 6 6 6s7-3 6-6c0-3-2-6-6-6s-6 3-6 6"
      />
      <path
        fill="#4E433D"
        d="M88 113c0 4 4 11 12 11s11-6 11-12-5-11-12-11c-6 1-11 6-11 12"
      />
      <path
        fill="#B2B1B2"
        d="M93 113c0 2 2 5 7 5s6-3 6-6-3-6-7-6c-3 0-6 3-6 7"
      />
      <path
        fill="#454C52"
        d="M53 88h63c2-1 5-2 5-6l-1-23c0-2-1-6-7-6H20c-3 0-5 1-6 5S9 68 9 75v13c1 2 1 4 5 4h21v23h18z"
      />
      <path fill="#FF2A23" d="M125 93s-2-1-3 1-1 10 0 11h4l-1-7v-5" />
      <path
        fill="#454C52"
        d="M119 94h-7l-1-2 1-1h7l1 1-1 2m0 4h-7l-1-1 1-1h7l1 1-1 1m0 5h-7l-1-2 1-1h7l1 2-1 1"
      />
      <path
        fill="#AFE3FB"
        d="M35 59H18c-2 0-2 2-3 5-1 4-3 11-3 20 0 2 2 2 3 2h20z"
      />
      <path fill="#868486" d="M18 78v11s5 1 6 0V79c-1-2-6-1-6-1" />
      <path
        fill="#AFE3FB"
        d="M38 62h12v50H38zm16 20V59h30v23zm34-23v23h26c2 0 3-1 3-4V63c0-2-2-4-4-4H88"
      />
    </symbol>
    <symbol id="NotoPoliceCarLight" viewBox="0 0 128 128">
      <path
        fill="#82AEC0"
        d="m118 112-2-19H12l-2 19c0 3 5 12 54 12s55-9 54-12"
      />
      <path
        fill="#E0E0E0"
        d="M12 94c0-6 23-11 52-11s52 5 52 11-23 12-52 12-52-6-52-12"
      />
      <path
        fill="#F44336"
        d="M98 16C95 7 85 4 64 4S33 7 30 16c-6 19-11 76-11 76s5 8 45 8 45-8 45-8-6-57-11-76"
      />
      <path
        fill="#C62828"
        d="M19 92c0-4 20-8 45-8s45 4 45 8-20 9-45 9-45-4-45-9"
        opacity=".9"
      />
      <radialGradient
        id="a"
        cx="64"
        cy="52.9"
        r="44"
        gradientTransform="translate(1 1) scale(.9913)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".6" stopColor="#FF6E40" />
        <stop offset=".8" stopColor="#FF7046" stopOpacity=".5" />
        <stop offset="1" stopColor="#FF7555" stopOpacity="0" />
      </radialGradient>
      <path
        fill="url(#a)"
        d="m73 36 21-23 1 1-16 26c-1 2 0 4 3 4h20l1 1-19 7c-3 1-3 4-1 6l23 17c1 0 0 1 0 0L79 64c-3 0-4 1-3 4l8 29h-1L69 71c-2-2-4-3-5 0L50 97h-1l7-29c0-2-1-3-3-2L22 82v-1l25-21c2-2 2-4-1-5l-21-8v-1l21 1c3 0 4-3 3-6L32 17h1l22 19c2 2 4 1 5-1l3-26h1l4 25c0 3 2 4 5 2"
      />
      <path
        fill="#FFD5CA"
        d="m66 34 9-17h1l-3 19c0 3 1 4 4 3l18-6 1 1-14 12c-3 2-2 5 0 6l19 6v1H81c-3 0-3 2-2 4l10 17-1 1-15-12c-2-2-4-2-4 1l-4 20h-1l-3-20c-1-2-2-3-5-1L42 82h-1l9-17c2-3 1-5-2-5l-20 1v-1l17-7c3-1 3-4 1-6L31 35v-1l18 6c3 1 5 0 4-3l-3-19h1l10 16c1 2 3 2 5 0"
      />
    </symbol>
    <symbol id="GermanFlagEmoji" viewBox="0 0 128 128">
      <rect x="0" y="21.33" width="128" height="25.6" fill="#000" />
      <rect x="0" y="46.93" width="128" height="25.6" fill="#DD0000" />
      <rect x="0" y="72.53" width="128" height="25.6" fill="#FFCC00" />
    </symbol>
    <symbol id="NotoBookmarkTabs" viewBox="0 0 128 128">
      <defs>
        <path
          id="notoBookmarkTabs0"
          fill="#FFF"
          d="M95.86 114.13H18.05c-2.21 0-4-1.79-4-4V8c0-2.21 1.79-4 4-4h77.81c2.21 0 4 1.79 4 4v102.13c0 2.21-1.79 4-4 4"
        />
      </defs>
      <path
        fill="#FFF"
        d="M106.75 123.88H32.94c-2.21 0-4-1.79-4-4V14.79c0-2.21 1.79-4 4-4h73.82c2.21 0 4 1.79 4 4v105.09c-.01 2.21-1.8 4-4.01 4"
      />
      <path
        fill="#6FBFF0"
        d="M106.75 10.79h-6.89v3h6.89c.55 0 1 .45 1 1v105.09c0 .55-.45 1-1 1H32.94c-.55 0-1-.45-1-1v-5.75h-3v5.75c0 2.21 1.79 4 4 4h73.82c2.21 0 4-1.79 4-4V14.79c-.01-2.2-1.8-4-4.01-4"
      />
      <use href="#notoBookmarkTabs0" />
      <path
        fill="#FFD54F"
        d="M117.95 52.62c0 .63-.38 1.18-.93 1.33c-1.39.37-4.25.98-8 .98H96.84c-.48 0-.95-.19-1.32-.55l-4.07-3.89c-.96-.92-.96-2.59 0-3.51l4.07-3.89c.37-.35.84-.55 1.32-.55h15.53c1.58 0 3.06-.3 4.1-.57c.76-.2 1.49.45 1.49 1.33v9.32z"
      />
      <path
        fill="#F3AB47"
        d="M117.35 42.11c-.1-.07-.21-.13-.33-.16c-.01 2.02-.07 4.04-.07 6.06v4.6c0 .2-.11.34-.18.36c-1.33.36-4.11.95-7.75.95H96.84c-.22 0-.45-.1-.63-.27l-3.48-3.33c-.52-.5-1.06-.94-1.37-1.62c-.19-.43-.28-.91-.22-1.38c-.01.11-.16.26-.2.37c-.05.12-.08.26-.12.38c-.07.27-.1.56-.08.84c.04.58.28 1.15.71 1.56l4.07 3.89c.37.35.84.55 1.32.55h12.18c3.75 0 6.61-.61 8-.98c.55-.15.93-.7.93-1.33v-9.33c0-.42-.23-.9-.6-1.16"
      />
      <path
        fill="#0091EA"
        d="M117.95 95.16c0 .63-.38 1.18-.93 1.33c-1.39.37-4.25.98-8 .98H96.84c-.48 0-.95-.19-1.32-.55l-4.07-3.89c-.96-.92-.96-2.59 0-3.51l4.07-3.89c.37-.35.84-.55 1.32-.55h15.53c1.58 0 3.06-.3 4.1-.57c.76-.2 1.49.45 1.49 1.33v9.32z"
      />
      <path
        fill="#01579B"
        d="M117.35 84.64c-.1-.07-.21-.13-.33-.16c-.01 2.02-.07 4.04-.07 6.06v4.6c0 .2-.11.34-.18.36c-1.33.36-4.11.95-7.75.95H96.84c-.22 0-.45-.1-.63-.27l-3.48-3.33c-.52-.5-1.06-.94-1.37-1.62c-.19-.43-.28-.91-.22-1.38c-.01.11-.16.26-.2.37c-.05.12-.08.26-.12.38c-.07.27-.1.56-.08.84c.04.58.28 1.15.71 1.56l4.07 3.89c.37.35.84.55 1.32.55h12.18c3.75 0 6.61-.61 8-.98c.55-.15.93-.7.93-1.33V85.8c0-.42-.23-.89-.6-1.16"
      />
      <path
        fill="#212121"
        d="M17.13 10.79c-.01.09 11.05.17 11.05.26l.76 27.79v78.34h69.98c2.21 0 4-1.79 4-4V11.05c0-.09-.02-.17-.03-.26z"
        opacity=".25"
      />
      <use href="#notoBookmarkTabs0" />
      <path
        fill="#6FBFF0"
        d="M95.86 7c.55 0 1 .45 1 1v102.13c0 .55-.45 1-1 1H18.05c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1zm0-3H18.05c-2.21 0-4 1.79-4 4v102.13c0 2.21 1.79 4 4 4h77.81c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4"
      />
      <path
        fill="none"
        stroke="#B0BEC5"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3.865"
        d="M27.11 30.48h57.11M27.11 44.25h57.11M27.11 58.03h57.11M27.11 71.81h57.11M27.11 85.58h24.43"
      />
      <path
        fill="#F44336"
        d="M116.09 33.46c0 .63-.38 1.18-.93 1.33c-1.39.37-4.25.98-8 .98H94.98c-.48 0-.95-.19-1.32-.55l-4.07-3.89c-.96-.92-.96-2.59 0-3.51l4.07-3.89c.37-.35.84-.55 1.32-.55h15.53c1.58 0 3.06-.3 4.1-.57c.76-.2 1.49.45 1.49 1.33v9.32z"
      />
      <path
        fill="#9CCC65"
        d="M116.09 76c0 .63-.38 1.18-.93 1.33c-1.39.37-4.25.98-8 .98H94.98c-.48 0-.95-.19-1.32-.55l-4.07-3.89c-.96-.92-.96-2.59 0-3.51l4.07-3.89c.37-.35.84-.55 1.32-.55h15.53c1.58 0 3.06-.3 4.1-.57c.76-.2 1.49.45 1.49 1.33V76z"
      />
      <path
        fill="#C62828"
        d="M115.5 22.96c-.1-.07-.21-.13-.33-.16c-.01 2.02-.07 4.04-.07 6.06v4.6c0 .2-.11.34-.18.36c-1.33.36-4.11.95-7.75.95H94.98c-.22 0-.45-.1-.63-.27l-3.48-3.33c-.52-.5-1.06-.94-1.37-1.62c-.19-.43-.28-.91-.22-1.38c-.01.11-.16.26-.2.37c-.05.12-.08.26-.12.38c-.07.27-.1.56-.08.84c.04.58.28 1.15.71 1.56l4.07 3.89c.37.35.84.55 1.32.55h12.18c3.75 0 6.61-.61 8-.98c.55-.15.93-.7.93-1.33v-9.33c0-.42-.23-.9-.59-1.16"
      />
      <path
        fill="#7CB342"
        d="M115.5 65.48c-.1-.07-.21-.13-.33-.16c-.01 2.02-.07 4.04-.07 6.06v4.6c0 .2-.11.34-.18.36c-1.33.36-4.11.95-7.75.95H94.98c-.22 0-.45-.1-.63-.27l-3.48-3.33c-.52-.5-1.06-.94-1.37-1.62c-.19-.43-.28-.91-.22-1.38c-.01.11-.16.26-.2.37c-.05.12-.08.26-.12.38c-.07.27-.1.56-.08.84c.04.58.28 1.15.71 1.56l4.07 3.89c.37.35.84.55 1.32.55h12.18c3.75 0 6.61-.61 8-.98c.55-.15.93-.7.93-1.33v-9.33c0-.42-.23-.9-.59-1.16"
      />
    </symbol>
    <symbol id="NRWFlowerEmoji" viewBox="0 0 32 32">
      <path
        fill="#e2001a"
        d="M15 2.3a12.4 12.4 0 0 1 2 0l.7.2 1.5.6c.2 0 .6.3.8.5l.7.8.3 1v1.9a.6.6 0 0 0 .4.3c.2 0 .5 0 .7-.2l1-.4h1.2c.4 0 .7.3 1 .4l.7.6 1 1.3.4.6.6 1.8V14l-.3.9-.5.9-1 .7-1 .2a2 2 0 0 0-.7.3l-.2.2v.3c0 .2.2.4.4.6.3.3.6.5.8.9l.3 1-.1 1.1-.3.9-1 1.3-.4.6-1.5 1-.7.4-1.6.4h-1c-.2 0-.6 0-1-.2l-.9-.6-.6-1a2 2 0 0 0-.4-.6h-.6a2 2 0 0 0-.5.6l-.5 1c-.3.2-.6.5-1 .6-.3.2-.7.2-1 .3h-1l-1.5-.5-.6-.3-1.6-1-.4-.7-1-1.3-.3-.9v-1c0-.4 0-.8.2-1.1.2-.4.5-.6.8-.9.2-.2.4-.4.4-.6v-.3s0-.2-.2-.2a2 2 0 0 0-.7-.3c-.3 0-.7 0-1-.2l-1-.7-.5-1-.3-.8V11.7l.6-1.7.4-.7L6 8l.7-.6 1-.4h1.2c.3 0 .6.3 1 .4l.7.2h.3l.1-.3V5.4l.3-1c.2-.4.5-.6.7-.8l.8-.6 1.5-.5.7-.2Z"
      />
      <circle cx="16" cy="14.6" r="6.6" fill="#fff" />
      <circle cx="16" cy="14.6" r="4.1" fill="#009036" />
      <path
        fill="#009036"
        d="m9 5 .6-1.3.6-1c0-.2-.8-.4-1.3-.5l-1-.1H7l-.4 1-.1 1v1.3c.1.1.8-.1 1.1-.2C8.2 5 8.7 5 9 5ZM4.7 18.3l-1-1-.8-.9c-.2 0-.6.7-.9 1l-.4 1c-.1.3-.3.7-.2 1 0 .2.4.5.7.6l.9.5 1.3.4.1-1.2c0-.6.2-1 .3-1.4ZM16 26.5l-1.3.7c-.3.1-1 .3-1 .5l.7 1 .7.8c.3.2.7.5 1 .5l.8-.5.7-.7c.3-.4.8-1 .7-1.1 0-.2-.7-.4-1-.5l-1.3-.7ZM27.3 18.3l.3 1.4.1 1.2 1.3-.4.9-.4.7-.7c0-.2 0-.7-.2-1l-.4-1c-.3-.3-.7-1-.9-1l-.7.9c-.4.5-.8.8-1.1 1ZM23 5c.3 0 .8 0 1.5.2l1 .2c.2-.1.2-.8.1-1.3l-.1-1-.5-1h-1l-1 .1c-.4.1-1.1.3-1.2.5l.6 1L23 5Z"
      />
    </symbol>
  </svg>
);

export default EmojiSprite;
