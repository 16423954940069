// EmojiSprite.jsx
import React from "react";
import PropTypes from "prop-types";

/**
 * This component references an <symbol> from your generated sprite file,
 * using #id in <use xlinkHref="#id" />.
 */
function useEmoji({ id }) {
  return (
    <svg className="customEmoji" role="img" aria-label={id}>
      <use href={`#${id}`} />
    </svg>
  );
}

useEmoji.propTypes = {
  /**
   * The "id" must match the <symbol id="..."> in your `emoji-sprite.svg`.
   * (For example, "NotoFactory" or "emoji-party").
   */
  id: PropTypes.string.isRequired,
};

export default useEmoji;
