import React, { useState, useRef, lazy, Suspense } from "react";
import clsx from "clsx";
import { useMediaQuery } from "@uidotdev/usehooks";

import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

// JSON data
import classificationData from "../../../data/survey/classification.json";

// Local components
import UseEmoji from "../emojis/useEmoji.jsx";

// Styles
import styles from "./quote-wall.module.css";

// Lazy-load your separate Swiper component
const MySwiper = lazy(() => import("./customSwiper.jsx"));

/* -------------------------------------------
   Main QuoteWall component
-------------------------------------------- */
const CAT_ALL = "all";

export const QuoteWall = () => {
  const [selectedCategory, setSelectedCategory] = useState(CAT_ALL);

  return (
    <>
      <Select
        size="lg"
        startDecorator={<FontAwesomeIcon icon={faList} />}
        placeholder="Kategorie auswählen"
        aria-label="Wähle die gewünschte Themenkategorie aus"
        variant="outlined"
        defaultValue={CAT_ALL}
        onChange={(_, value) => {
          setSelectedCategory(value);
        }}
      >
        <Option key={CAT_ALL} value={CAT_ALL}>
          Alle Themen
        </Option>
        {Object.entries(classificationData)
          .filter(([id]) => id !== "0")
          // Sort categories by "order" attribute in classificationData
          .sort(([, a], [, b]) => parseInt(a.order) - parseInt(b.order))
          .map(([id, { emoji, label }]) => (
            <Option key={id} value={id}>
              <UseEmoji id={emoji} />
              {label}
            </Option>
          ))}
      </Select>

      {/*
        Render two Swipers, each with a fallback that uses
        the same classes as the final Swiper container to reserve space.
      */}

      <SuspendedSwiper mode="mobile" selectedCategory={selectedCategory} />
      <SuspendedSwiper mode="desktop" selectedCategory={selectedCategory} />
    </>
  );
};

const SuspendedSwiper = ({ mode, selectedCategory }) => {
  const containerRef = useRef(null);

  const isClient = typeof window !== "undefined";

  return (
    <div
      ref={containerRef}
      className={clsx(
        styles.swiperWrapper,
        mode === "mobile" && styles.swiperWrapperMobile,
        mode === "desktop" && styles.swiperWrapperDesktop,
      )}
    >
      <Suspense fallback={<Loader />}>
        {isClient && (
          <MaybeSwiper
            mode={mode}
            selectedCategory={selectedCategory}
            containerRef={containerRef}
          />
        )}
      </Suspense>
    </div>
  );
};

const MaybeSwiper = ({ mode, selectedCategory, containerRef }) => {
  const mediaQuery =
    mode === "mobile" ? "not (min-width: 800px)" : "(min-width: 800px)";

  const shouldRender = useMediaQuery(mediaQuery);

  return shouldRender ? (
    <MySwiper
      mode={mode}
      selectedCategory={selectedCategory}
      containerRef={containerRef}
    />
  ) : (
    <Loader />
  );
};

const Loader = () => (
  <div className={styles.loaderWrapper}>
    <span className="loader"></span>
  </div>
);
